import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { compose } from 'recompose';
import { Segment, Container, Button, Image, Header, Item } from 'semantic-ui-react';
import { Icon, Menu } from 'semantic-ui-react'
import { strings } from '../core/strings.js'
import * as config from '../Config';
import logo from '../assets/logo-header.png'
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';

class SideMenu extends Component {

    /*static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };*/

    constructor(props) {
        super(props);

        const authToken = tokenCookie(props);

        this.state = {
            loading: false,
            authToken: authToken
        }

    }

    routeChange = (path) => {
        this.props.history.push("/" + path);
    }

    onLogoutClick = async () => {
        this.setState({
            loading: true
        });
        try {
            let response = await API.logout(this.state.authToken);
            const { cookies } = this.props;
            cookies.remove('USER_TOKEN');
            this.props.history.push("/");
            this.setState({
                loading: false
            });

        } catch (error) {
            this.setState({
                loading: false
            });
            alert("LOGOUT ERROR");
        }
    }

    render() {
        return (
            <div>
                <Segment raised textAlign='center'>
                    <Container >
                        <Image style={{ maxWidth: 80 }} height='50' src={logo} as='a' href="" />
                    </Container>
                    <Menu size='massive' secondary stackable fluid icon='labeled' vertical>
                        <Menu.Item
                            name={config.MENU_ITEM_ACCOUNTING}
                            active={this.props.activeItem === config.MENU_ITEM_ACCOUNTING}
                            onClick={() => this.routeChange(config.MENU_ITEM_ACCOUNTING)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_ACCOUNTING ? 'main-color' : 'secondary-color'} name='list' />
                            <p style={{wordWrap: "break-word"}} className={this.props.activeItem === config.MENU_ITEM_ACCOUNTING ? 'main-color' : 'secondary-color'}>{strings.accounting}</p>
                        </Menu.Item>
                        <Menu.Item
                            name={config.MENU_ITEM_PRESCRIPTIONS}
                            active={this.props.activeItem === config.MENU_ITEM_PRESCRIPTIONS}
                            onClick={() => this.routeChange(config.MENU_ITEM_PRESCRIPTIONS)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_PRESCRIPTIONS ? 'main-color' : 'secondary-color'} name='list' />
                            <p style={{wordWrap: "break-word"}} className={this.props.activeItem === config.MENU_ITEM_PRESCRIPTIONS ? 'main-color' : 'secondary-color'}>{strings.prescriptions}</p>
                        </Menu.Item>
                        <Menu.Item
                            name={config.MENU_ITEM_USER}
                            active={this.props.activeItem === config.MENU_ITEM_USER}
                            onClick={() => this.routeChange(config.MENU_ITEM_USER)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_USER ? 'main-color' : 'secondary-color'} name='user' />
                            <p style={{wordWrap: "break-word"}} className={this.props.activeItem === config.MENU_ITEM_USER ? 'main-color' : 'secondary-color'}>{strings.user}</p>
                        </Menu.Item>
                        <Menu.Item
                            name={config.MENU_ITEM_ADMIN_DENTISTS}
                            active={this.props.activeItem === config.MENU_ITEM_ADMIN_DENTISTS}
                            onClick={() => this.routeChange(config.MENU_ITEM_ADMIN_DENTISTS)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_ADMIN_DENTISTS ? 'main-color' : 'secondary-color'} name='doctor' />
                            <p style={{wordWrap: "break-word"}} className={this.props.activeItem === config.MENU_ITEM_ADMIN_DENTISTS ? 'main-color' : 'secondary-color'}>{strings.dentists_manager}</p>
                        </Menu.Item>
                        <Menu.Item
                            name={config.MENU_ITEM_ADMIN_OPERATORS}
                            active={this.props.activeItem === config.MENU_ITEM_ADMIN_OPERATORS}
                            onClick={() => this.routeChange(config.MENU_ITEM_ADMIN_OPERATORS)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_ADMIN_OPERATORS ? 'main-color' : 'secondary-color'} name='id card' />
                            <p style={{wordWrap: "break-word"}} className={this.props.activeItem === config.MENU_ITEM_ADMIN_OPERATORS ? 'main-color' : 'secondary-color'}>{strings.operators_manager}</p>
                        </Menu.Item>
                        <Menu.Item
                            name={config.MENU_ITEM_ADMIN_USERS}
                            active={this.props.activeItem === config.MENU_ITEM_ADMIN_USERS}
                            onClick={() => this.routeChange(config.MENU_ITEM_ADMIN_USERS)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_ADMIN_USERS ? 'main-color' : 'secondary-color'} name='id card outline' />
                            <p style={{wordWrap: "break-word"}} className={this.props.activeItem === config.MENU_ITEM_ADMIN_USERS ? 'main-color' : 'secondary-color'}>{strings.users_manager}</p>
                        </Menu.Item>
                        <Menu.Item
                            name={config.MENU_ITEM_ADMIN_MATERIALS}
                            active={this.props.activeItem === config.MENU_ITEM_ADMIN_MATERIALS}
                            onClick={() => this.routeChange(config.MENU_ITEM_ADMIN_MATERIALS)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_ADMIN_MATERIALS ? 'main-color' : 'secondary-color'} name='tags' />
                            <p style={{wordWrap: "break-word"}} className={this.props.activeItem === config.MENU_ITEM_ADMIN_MATERIALS ? 'main-color' : 'secondary-color'}>{strings.materials_label}</p>
                        </Menu.Item>

                        <Menu.Item
                            name={config.MENU_ITEM_WORKSLIST}
                            active={this.props.activeItem === config.MENU_ITEM_WORKSLIST}
                            onClick={() => this.routeChange(config.MENU_ITEM_WORKSLIST)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_WORKSLIST ? 'main-color' : 'secondary-color'} name='ordered list' />
                            <p style={{wordWrap: "break-word"}} className={this.props.activeItem === config.MENU_ITEM_WORKSLIST ? 'main-color' : 'secondary-color'}>{strings.workslist}</p>
                        </Menu.Item>
                        <Menu.Item
                            name={config.MENU_ITEM_DASHBOARD}
                            active={this.props.activeItem === config.MENU_ITEM_DASHBOARD}
                            onClick={() => this.routeChange(config.MENU_ITEM_DASHBOARD)}>
                            <Icon className={this.props.activeItem === config.MENU_ITEM_DASHBOARD ? 'main-color' : 'secondary-color'} name='dashboard' />
                            <p style={{wordWrap: "break-word"}} className={this.props.activeItem === config.MENU_ITEM_DASHBOARD ? 'main-color' : 'secondary-color'}>{strings.dashboard}</p>
                        </Menu.Item>
                    </Menu>
                    <Button loading={this.state.loading} fluid icon labelPosition='right' onClick={this.onLogoutClick}>
                        <Icon name='sign-out' />
                        {strings.logout}
                    </Button>
                </Segment>
                <Segment textAlign='center' raised>
                    <Header as='h5' dividing>{strings.today}</Header>
                    <p><b>{new Date().toLocaleDateString().replaceAll('/','-')}</b></p>
                </Segment>
                <Segment textAlign='center' raised>
                    <Header as='h5' dividing>{strings.support}</Header>
                    <Item style={{wordWrap: "break-word"}} icon='mail' content={<a href={"mailto:" + config.SUPPORT_EMAIL} target="_blank" >{config.SUPPORT_EMAIL}</a>} />
                </Segment>
                <Segment textAlign='center' raised>
                    <Header as='h5' dividing>1.1.3</Header>
                </Segment>
            </div>
        )
    }
}

export default compose(
    withCookies,
    withRouter)(SideMenu);