import axios from 'axios';
import * as config from '../Config';

class API {

    constructor() {
        switch (config.STATE) {
            case 'PROD': this.baseUrl = config.BASE_API_URL_PROD; break;
            case 'DEV': this.baseUrl = config.BASE_API_URL_DEV; break;
            case 'LOCAL': this.baseUrl = config.BASE_API_URL_LOCAL; break;
            default: this.baseUrl = config.BASE_API_URL_LOCAL; break;
        }

    }

    reset_password = (email) => {
        let params = { email: email, lang: config.BROWSER_LANG.substring(0, 2) };

        return axios({
            method: 'get',
            url: this.baseUrl + 'reset_password',
            params: params
        });
    }

    b64toBlob = (dataURI) => {

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpg' });
    }

    login = (email, password) => {

        var bodyFormData = new FormData();
        bodyFormData.set('email', email);
        bodyFormData.set('password', password);

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'login',
            method: 'post',
            data: bodyFormData
        });
    }

    loginToken = (token) => {

        let params = { token: token };

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'login_token',
            params: params,
            method: 'post'
        });
    }

    logout = (token) => {
        return axios({
            method: 'delete',
            url: this.baseUrl + 'logout',
            params: {
                token: token
            }
        });
    }

    get_prescriptions = (token, orderby, searchFilters, page_results = 25) => {
        let params = { token: token, page_results: page_results };
         
        var order = 1;
        orderby.forEach(e => {
            params[e] = order;
            order = order + 1;
        })

        for (var index in searchFilters) {
            if (searchFilters[index] != '') {
                params[index] = searchFilters[index];
            }
        }

        return axios({
            method: 'get',
            url: this.baseUrl + 'prescriptions',
            params: params
        });
    }

    get_prescription = (token, id) => {
        let params = { token: token };

        return axios({
            method: 'get',
            url: this.baseUrl + 'prescriptions/' + id,
            params: params
        });
    }

    get_materials = (token) => {

        let params = { token: token };

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'materials',
            params: params,
            method: 'get'
        });
    }

    get_page = (page) => {

        if (config.STATE == 'LOCAL') {
            page = page.slice(0, 4) + page.slice(5);
        }

        return axios.get(page, {
        });
    }

    put_dentist = (token, dentist) => {

        var params = new URLSearchParams();
        params.set('name', dentist.name);
        params.set('surname', dentist.surname);
        params.set('description', dentist.description);
        params.set('email', dentist.email);
        params.set('address', dentist.address);
        params.set('phone_number', dentist.phone_number);

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'dentists/' + dentist.id_dentist,
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }

    post_prescription = (token, prescription) => {

        var bodyFormData = new FormData();
        bodyFormData.set('patient_code', prescription.patient_code);
        bodyFormData.set('description', prescription.description);
        bodyFormData.set('date_requested', prescription.date_requested);
        bodyFormData.set('time_requested', prescription.time_requested);
        if (prescription.ref_prev_prescription != null && prescription.ref_prev_prescription != '') {
            bodyFormData.set('ref_prev_prescription', prescription.ref_prev_prescription);
        }
        bodyFormData.set('tint', prescription.tint);
        bodyFormData.set('web', 1);

        prescription.materials.forEach(id => {
            bodyFormData.append('ids_materials[]', id);
        });

        prescription.images.forEach((image, i) => {
            bodyFormData.set('img' + (i + 1), image);
        });

        prescription.teeth.forEach((t, i) => {
            bodyFormData.append('ids_teeth[]', t);
        });

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'prescriptions',
            method: 'post',
            params: {
                token: token
            },
            data: bodyFormData
        });
    }

    put_prescription = (token, prescription) => {

        var bodyFormData = new URLSearchParams();
        bodyFormData.set('patient_code', prescription.patient_code);
        bodyFormData.set('box_number', prescription.box_number);
        bodyFormData.set('patient_gender', prescription.patient_gender);
        bodyFormData.set('patient_age', prescription.patient_age);
        bodyFormData.set('description', prescription.description);
        bodyFormData.set('date_requested', prescription.date_requested);
        bodyFormData.set('date_start_work', prescription.date_start_work);
        bodyFormData.set('time_requested', prescription.time_requested);
        if (prescription.ref_prev_prescription != null && prescription.ref_prev_prescription != '') {
            bodyFormData.set('ref_prev_prescription', prescription.ref_prev_prescription);
        }
        bodyFormData.set('tint', prescription.tint);
        bodyFormData.set('web', 1);
        bodyFormData.set('payment', prescription.payment == true ? 1 : 0);
        bodyFormData.set('id_prescription', prescription.id_prescription);

        prescription.materials.forEach(id => {
            bodyFormData.append('ids_materials[]', id);
        });

        prescription.images.forEach((image, i) => {
            bodyFormData.set('img' + (i + 1), image);
        });

        prescription.files.forEach((file, i) => {
            bodyFormData.set('file' + (i + 1) + '_data', file.data);
            bodyFormData.set('file' + (i + 1) + '_name', file.name);
        });

        prescription.teeth.forEach((t, i) => {
            bodyFormData.append('ids_teeth[]', t);
        });

        bodyFormData.set('status', prescription.status);


        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'prescriptions/' + prescription.id_prescription,
            method: 'put',
            params: {
                token: token
            },
            data: bodyFormData
        });
    }


    delete_prescription = (token, prescriptionId) => {

        return axios({
            url: this.baseUrl + 'prescriptions/' + prescriptionId,
            method: 'delete',
            params: {
                token: token
            }
        });
    }


    get_dentists = (token) => {
        let params = { token: token };

        return axios({
            method: 'get',
            url: this.baseUrl + 'dentists',
            params: params
        });
    }

    get_dentist = (token, id) => {
        let params = { token: token };

        return axios({
            method: 'get',
            url: this.baseUrl + 'dentists/' + id,
            params: params
        });
    }

    post_dentist = (token, dentist) => {

        var bodyFormData = new FormData();
        bodyFormData.set('name', dentist.name);
        bodyFormData.set('surname', dentist.surname);
        bodyFormData.set('description', dentist.description);
        bodyFormData.set('email', dentist.email);
        bodyFormData.set('address', dentist.address);
        bodyFormData.set('phone_number', dentist.phone_number);
        bodyFormData.set('password', dentist.password);

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'dentists',
            method: 'post',
            params: {
                token: token
            },
            data: bodyFormData
        });
    }

    get_users = (token) => {
        let params = { token: token };

        return axios({
            method: 'get',
            url: this.baseUrl + 'users',
            params: params
        });
    }

    get_user = (token, id) => {
        let params = { token: token };

        return axios({
            method: 'get',
            url: this.baseUrl + 'users/' + id,
            params: params
        });
    }

    put_user = (token, user) => {

        var params = new URLSearchParams();
        params.set('name', user.name);
        params.set('surname', user.surname);
        params.set('role', user.role);
        params.set('email', user.email);

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'users/' + user.id_user,
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }

    put_user_password = (token, id_user, old_password, new_password) => {

        var params = new URLSearchParams();
        params.set('old_password', old_password);
        params.set('new_password', new_password);

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'users/' + id_user,
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }

    post_user = (token, user) => {

        var bodyFormData = new FormData();
        bodyFormData.set('name', user.name);
        bodyFormData.set('surname', user.surname);
        bodyFormData.set('role', user.role);
        bodyFormData.set('email', user.email);
        bodyFormData.set('password', user.password);

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'users',
            method: 'post',
            params: {
                token: token
            },
            data: bodyFormData
        });
    }

    get_operators = (token, hidden = false) => {
        let params = { token: token };
        if(hidden){
            params.hidden = 1;
        }

        return axios({
            method: 'get',
            url: this.baseUrl + 'operators',
            params: params
        });
    }

    get_operator = (token, id) => {
        let params = { token: token };

        return axios({
            method: 'get',
            url: this.baseUrl + 'operators/' + id,
            params: params
        });
    }

    put_operator = (token, operator) => {

        var params = new URLSearchParams();
        params.set('name', operator.name);
        params.set('surname', operator.surname);
        params.set('lab', operator.lab);
        params.set('inspector', operator.inspector ? 1 : 0);
        params.set('hidden', operator.hidden ? 1 : 0);
        if (operator.new_image != undefined && operator.new_image != null) {
            params.set('img', operator.new_image);
        }

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'operators/' + operator.id_operator,
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }

    post_operator = (token, operator) => {

        var bodyFormData = new FormData();
        bodyFormData.set('name', operator.name);
        bodyFormData.set('surname', operator.surname);
        bodyFormData.set('lab', operator.lab);
        bodyFormData.set('inspector', operator.inspector ? 1 : 0);
        bodyFormData.set('password', operator.password);

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'operators',
            method: 'post',
            params: {
                token: token
            },
            data: bodyFormData
        });
    }

    delete_task = (token, taskId) => {

        return axios({
            url: this.baseUrl + 'tasks/' + taskId,
            method: 'delete',
            params: {
                token: token
            }
        });
    }

    get_tasks = (token, id) => {

        return axios({
            url: this.baseUrl + 'prescriptions/' + id + '/tasks',
            method: 'get',
            params: {
                token: token
            }
        });
    }

    put_task = (token, id_task, id_operator, type, completed, inspector, date) => {

        var params = new URLSearchParams();
        params.set('id_operator', id_operator);
        params.set('type', type);
        params.set('completed', completed == true ? 1 : 0);
        params.set('inspector', inspector == true ? 1 : 0);
        params.set('date_assignment', date);

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'tasks/' + id_task,
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }

    post_task_works = (token, id_task, works) =>{

        var params = new FormData();
        params.set('works', JSON.stringify(works));
        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'tasks/' + id_task + "/works",
            method: 'post',
            params: {
                token: token
            },
            data: params
        });
    }


    post_task_materials = (token, id_task, materials) =>{

        var params = new FormData();
        params.set('materials', JSON.stringify(materials));
        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'tasks/' + id_task + "/materials",
            method: 'post',
            params: {
                token: token
            },
            data: params
        });
    }

    put_task_payment = (token, id_task, payment) => {

        var params = new URLSearchParams();
        params.set('payment', payment == true ? 1 : 0);

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'tasks/' + id_task + '/payment',
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }

    put_task_workcode = (token, id_task, work_code) => {

        var params = new URLSearchParams();
        params.set('work_code', work_code);

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'tasks/' + id_task + '/work_code',
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }

    put_task_discount = (token, id_task, discount) => {

        var params = new URLSearchParams();
        params.set('discount', discount);

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'tasks/' + id_task + '/discount',
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }


    put_task_invoice_description = (token, id_task, invoice_description) => {

        var params = new URLSearchParams();
        params.set('invoice_description', invoice_description);

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'tasks/' + id_task + '/invoice_description',
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }

    put_task_material_batch_number = (token, id_task, id_material, value) => {

        var params = new URLSearchParams();
        params.set('batch_number', value);

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'tasks/' + id_task + '/materials_certification/' + id_material,
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }

    post_task = (token, prescriptionId,
        id_operator, type, completed, inspector, date) => {
        var bodyFormData = new FormData();
        bodyFormData.set('id_prescription', prescriptionId);
        bodyFormData.set('id_operator', id_operator);
        bodyFormData.set('type', type);
        bodyFormData.set('completed', completed == true ? 1 : 0);
        bodyFormData.set('inspector', inspector == true ? 1 : 0);
        bodyFormData.set('date_assignment', date);

        

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'tasks',
            method: 'post',
            params: {
                token: token
            },
            data: bodyFormData
        });
    }

    post_report = (token, prescriptionId,
        report) => {

        var bodyFormData = new FormData();
        bodyFormData.set('report', report);

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'prescriptions/' + prescriptionId + '/report',
            method: 'post',
            params: {
                token: token
            },
            data: bodyFormData
        });
    }

    put_prescription_payment = (token, prescriptionId) => {

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'prescriptions/' + prescriptionId + '/payment',
            method: 'put',
            params: {
                token: token
            }
        });
    }

    get_materials_certification = (token) => {

        return axios({
            url: this.baseUrl + 'materials_certification',
            method: 'get',
            params: {
                token: token
            }
        });
    }

    get_workslist = (token, complete) => {

        if(complete !== true){
            complete = false
        }

        return axios({
            url: this.baseUrl + 'workslist',
            method: 'get',
            params: {
                token: token,
                complete:complete
            }
        });
    }

    post_work = (token, work) => {

        var params = new FormData();
        work.name_it = work.name_it == undefined ? '' : work.name_it;
        params.set('name_it', work.name_it);
        
        work.name_fr = work.name_fr == undefined ? '' : work.name_fr;
        params.set('name_fr', work.name_fr);
        
        work.name_en = work.name_en == undefined ? '' : work.name_en;
        params.set('name_en', work.name_en);

        work.code = work.code == undefined ? '' : work.code;
        params.set('code', work.code);

        work.price = work.price == undefined ? '' : work.price;
        params.set('price', work.price);


        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'workslist',
            method: 'post',
            params: {
                token: token
            },
            data: params
        });
    }

    post_work_pers  = (token, pers) => {

        var params = new FormData();

        pers.id_dentist = pers.id_dentist == undefined ? '' : pers.id_dentist;
        params.set('id_dentist', pers.id_dentist);

        pers.code = pers.code == undefined ? '' : pers.code;
        params.set('code', pers.code);

        pers.price = pers.price == undefined ? '' : pers.price;
        params.set('price', pers.price);

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'workslist/' + pers.id_work + '/personalisations/add',
            method: 'post',
            params: {
                token: token
            },
            data: params
        });
    }

    revoke_work_pers  = (token, id_personalisation) => {

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'workslist/personalisations/' + id_personalisation + '/revoke',
            method: 'put',
            params: {
                token: token
            }
        });
    }

    get_work = (token, id) => {

        return axios({
            url: this.baseUrl + 'workslist/' + id,
            method: 'get',
            params: {
                token: token
            }
        });
    }

    put_work = (token, work) => {

        var params = new URLSearchParams();
        params.set('name_it', work.name_it);
        params.set('name_fr', work.name_fr);
        params.set('name_en', work.name_en);

        params.set('code', work.code);
        params.set('price', work.price);

        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'workslist/' + work.id_work,
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }


    get_material_certification = (token, id) => {

        return axios({
            url: this.baseUrl + 'materials_certification/' + id,
            method: 'get',
            params: {
                token: token
            }
        });
    }

    put_material = (token, material) => {

        var params = new URLSearchParams();
        params.set('name_it', material.name_it);
        params.set('name_fr', material.name_fr);
        params.set('name_en', material.name_en);

        params.set('type', material.type);
        params.set('category', material.category);
        params.set('classification', material.classification);

        params.set('producer', material.producer);
        params.set('ce', material.ce);
        params.set('norms', material.norms);
        params.set('batch_number', material.batch_number);


        return axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: this.baseUrl + 'materials_certification/' + material.id_material,
            method: 'put',
            params: {
                token: token
            },
            data: params
        });
    }

    post_material_certification = (token, material) => {

        var params = new FormData();
        material.name_it = material.name_it == undefined ? '' : material.name_it;
        params.set('name_it', material.name_it);
        
        material.name_fr = material.name_fr == undefined ? '' : material.name_fr;
        params.set('name_fr', material.name_fr);
        
        material.name_en = material.name_en == undefined ? '' : material.name_en;
        params.set('name_en', material.name_en);

        material.type = material.type == undefined ? '' : material.type;
        params.set('type', material.type);

        material.category = material.category == undefined ? '' : material.category;
        params.set('category', material.category);

        material.classification = material.classification == undefined ? '' : material.classification;
        params.set('classification', material.classification);

        material.producer = material.producer == undefined ? '' : material.producer;
        params.set('producer', material.producer);

        material.ce = material.ce == undefined ? '' : material.ce;
        params.set('ce', material.ce);

        material.norms = material.norms == undefined ? '' : material.norms;
        params.set('norms', material.norms);

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: this.baseUrl + 'materials_certification',
            method: 'post',
            params: {
                token: token
            },
            data: params
        });
    }

    post_certificate = (token, prescriptionId) => {

        return axios({
            url: this.baseUrl + 'prescriptions/' + prescriptionId + '/certificate',
            method: 'post',
            params: {
                token: token
            }
        });
    }

    post_additional_cost = (token, prescriptionId, description, price) => {

        var params = new FormData();
        params.set('description', description);
        params.set('price', price);

        return axios({
            url: this.baseUrl + 'prescriptions/' + prescriptionId + '/additional_costs',
            method: 'post',
            params: {
                token: token
            },
            data: params
            
        });
    }

    delete_additional_cost = (token, additionalCostId) => {

        return axios({
            url: this.baseUrl + 'prescriptions/additional_costs/'+additionalCostId,
            method: 'delete',
            params: {
                token: token
            }
        });
    }
    get_data = (token,id) => {
        let params = { token: token };
        params.id_operator = id;

        return axios({
            method: 'get',
            url: this.baseUrl + 'dashboard/operators_productivity',
            params: params
        });
    }

    

}
export default (new API());