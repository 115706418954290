import React, { Component } from 'react';
import { Grid, Segment, Form, Button, Loader, Image, Icon, Dropdown, Divider, Header, List, Confirm, Message, Label } from 'semantic-ui-react';
import "../App.css";
import { strings } from '../core/strings.js'
import { compose } from 'recompose';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";
import API from '../core/api';
import tokenCookie from '../core/tokenCookie';
import SideMenu from './SideMenu';
import * as config from '../Config';
import {
    DateInput, TimeInput
} from 'semantic-ui-calendar-react-17';
import dateTimeFormatter from '../utils/dateTimeFormatter';
import moment from 'moment';
import 'moment/locale/it';
import Planning from './Planning';
import ReportModal from './ReportModal';
import PrintModal from './PrintModal';
import ErrorModal from '../utils/ErrorModal';
import OkModal from '../utils/OkModal';
import Canvas from './Canvas';

const status_label = [
    strings.status_preparing,
    strings.status_progress,
    strings.status_completed,
    strings.status_shipped
];

const paymentOptions = [
    { key: 'paid', text: strings.paid, value: true },
    { key: 'free', text: strings.free, value: false },
]

const status_label_color = [
    'grey',
    'yellow',
    'blue',
    'green'
];



const colorOptions = config.colors[config.BROWSER_LANG.substring(0, 2)].map(element => {

    var myEl = { key: element, text: element, value: element }
    return myEl;
})

const statusOptions = status_label.map(element => {
    var myEl = { key: element, text: element, value: element }
    return myEl;
})


class Prescription extends Component {
    constructor(props) {
        super(props);

        const accessToken = tokenCookie(props);
        this.previous_number = 1
        this.state = {
            accessToken: accessToken,
            prescription: null,
            materials: [],
            loading: false,
            prev_id: null,
            edit: false,
            new_images: [],
            new_files: [],
            original_materials: [],
            confirmOpen: false,
            report: '',
            ok: false,
            error: false,
            teeth: []
        }
        window.enabled = false;
        this.imageInputRef = React.createRef();
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getPrescription(this.props.prescriptionId);
        this.getMaterials();
    }

    changePage(newId) {
        this.setState({
            prev_id: this.state.prescription.id_prescription,
            edit: false
        })
        this.getPrescription(newId);
        this.getMaterials();
    }

    changePage2(newId) {
        this.getPrescription(newId);
        this.getMaterials();
    }

    getMaterials = async () => {
        try {
            let response = await API.get_materials(this.state.accessToken);
            if (response.data.data != null) {
                var materials = response.data.data

                const new_materials = materials.map(item => {
                    var newEl = {};
                    if (config.BROWSER_LANG.substring(0, 2) == 'it') {
                        newEl.key = item.id_material;
                        newEl.value = item.name_it;
                        newEl.text = item.name_it;
                    } else if (config.BROWSER_LANG.substring(0, 2) == 'en') {
                        newEl.key = item.id_material;
                        newEl.value = item.name_en;
                        newEl.text = item.name_en;
                    } else {
                        newEl.key = item.id_material;
                        newEl.value = item.name_fr;
                        newEl.text = item.name_fr;
                    }

                    return newEl;
                })

                this.setState({
                    original_materials: materials,
                    materials: new_materials,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    getPrescription = async (prescriptionId) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.get_prescription(this.state.accessToken, prescriptionId);
            if (response.data.data != null) {
                var prescription = response.data.data
                prescription.datetime = dateTimeFormatter(prescription.datetime);
                prescription.date_requested = dateTimeFormatter(prescription.date_requested);
                prescription.date_start_work = dateTimeFormatter(prescription.date_start_work);

                prescription.materials = prescription.materials.map(item => {
                    if (config.BROWSER_LANG.substring(0, 2) == 'it') {
                        return item.name_it;
                    } else if (config.BROWSER_LANG.substring(0, 2) == 'en') {
                        return item.name_en;
                    } else {
                        return item.name_fr;
                    }
                })

                var teeth = prescription.teeth.map(
                    function (item, i) {
                        return item.id_tooth
                    })
                window.items = teeth;

                this.setState({
                    prescription: prescription,
                    loading: false,
                    teeth: teeth
                });

            } else if (response.data.error.code == '23000') {
                const { cookies } = this.props;
                cookies.remove('USER_TOKEN');
                this.props.history.push("/");
            } else {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
        }
    }


    goPreviousPrescription = () => {
        if (this.state.prescription.ref_prev_prescription != null) {
            this.previous_number = this.previous_number + 1;
            this.props.history.push("/" + config.MENU_ITEM_PRESCRIPTIONS + "/" + this.state.prescription.ref_prev_prescription)
            this.changePage(this.state.prescription.ref_prev_prescription);
        }
    }

    goBack = () => {
        this.previous_number = this.previous_number - 1;;
        if (this.previous_number <= 0) {
            this.props.history.goBack();
        }
        else {
            this.props.history.goBack();
            this.changePage2(this.state.prev_id);

        }
    }

    editButton = () => {
        //if (this.state.prescription.status < 1) {
            this.setState({
                edit: true
            })
            window.enabled = true;
        //}
    }

    deleteButton = () => {

        this.deletePrescription();
    }

    cancelEditButton = () => {
        this.setState({
            edit: false
        })
        window.enabled = false;
        this.getPrescription(this.state.prescription.id_prescription);
    }

    saveButton = () => {
        this.savePrescription();
    }

    deletePrescription = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.delete_prescription(this.state.accessToken, this.state.prescription.id_prescription);

            this.setState({
                loading: false
            });

            if (response.data.data == null || response.data.data === false) {
                // TODO handle error
                this.setState({
                    error: true,
                    loading: false
                });
            }
            this.props.history.goBack();
        } catch (error) {
            // TODO handle error
            this.setState({
                loading: false
            });
        }
    }

    createCertificate = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_certificate(
                this.state.accessToken, this.state.prescription.id_prescription
            );

            if (response.data.data == true) {
                this.setState({
                    loading: false,
                    ok: true
                });
            } else {
                this.setState({
                    loading: false,
                    error: true
                });
            }

            this.getPrescription(this.state.prescription.id_prescription);
        } catch (error) {
            this.setState({
                loading: false,
                error: true
            });
        }
    }

    sendReport = async () => {

        this.setState({
            loading: true,
        })

        try {
            let response = await API.post_report(
                this.state.accessToken, this.state.prescription.id_prescription,
                this.state.report
            );
            if (response.data.data == true) {
                this.setState({
                    loading: false,
                    ok: true
                });
            } else {
                this.setState({
                    loading: false,
                    error: true
                });
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: true
            });
        }
    }

    savePrescription = async () => {

        var prescription = Object.assign({}, this.state.prescription);
        prescription.date_requested = dateTimeFormatter(prescription.date_requested);
        prescription.date_start_work = dateTimeFormatter(prescription.date_start_work);
        prescription.materials = prescription.materials.map(name => {
            var m = this.state.original_materials.filter(function (item) {
                if (config.BROWSER_LANG.substring(0, 2) == 'it') {
                    return item.name_it === name;
                } else if (config.BROWSER_LANG.substring(0, 2) == 'en') {
                    return item.name_en === name;
                } else {
                    return item.name_fr === name;
                }
            }).
                map(function (item) { return item.id_material; });
            return m;
        });

        prescription.teeth = [...window.items];
        prescription.images = [...this.state.new_images];
        prescription.files = [...this.state.new_files];

        this.setState({
            loading: true,
        })

        try {
            let response = await API.put_prescription(this.state.accessToken, prescription);

            this.setState({
                loading: false
            });

            if (response.data.data == null || response.data.data === false) {
                this.setState({
                    error: true,
                    loading: false
                });
            } else {
                this.props.history.goBack();
            }

        } catch (error) {
            // TODO handle error
            alert("KO");
            this.setState({
                loading: false
            });
        }
    }

    handleChangePrescriptionStatus = (e, { value }) => {

        var prescription = Object.assign({}, this.state.prescription);
        prescription.status = status_label.indexOf(value);
        this.setState({ prescription: prescription, edit: true });

    }

    handleChangePatienCode = (e, { value }) => {
        if (this.state.edit) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.patient_code = value;
            this.setState({ prescription: prescription });
        }
    }

    handleChangeBoxNumber = (e, { value }) => {
        if (this.state.edit) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.box_number = value;
            this.setState({ prescription: prescription });
        }
    }

    handleChangePatientAge = (e, { value }) => {
        if (this.state.edit) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.patient_age = value;
            this.setState({ prescription: prescription });
        }
    }

    handleChangeDateRequested = (e, { value }) => {
        if (this.state.edit) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.date_requested = value;
            this.setState({ prescription: prescription });
        }
    }

    handleChangeDateStartWork = (e, { value }) => {
        if (this.state.edit) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.date_start_work = value;
            this.setState({ prescription: prescription });
        }
    }

    handleChangeTimeRequested = (e, { value }) => {
        if (this.state.edit) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.time_requested = value;
            this.setState({ prescription: prescription });
        }
    }

    handleChangePayment = (e, { value }) => {
        if (this.state.edit) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.payment = value;
            this.setState({ prescription: prescription });
        }
    }

    handleChangeDescription = (e, { value }) => {
        if (this.state.edit) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.description = value;
            this.setState({ prescription: prescription });
        }
    }

    handleChangeTint = (e, { value }) => {
        if (this.state.edit) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.tint = value;
            this.setState({ prescription: prescription });
        }
    }

    handleChangeMaterials = (e, { value }) => {
        if (this.state.edit) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.materials = value;
            this.setState({ prescription: prescription });
        }
    }

    handleChangeRefPrevPrescription = (e, { value }) => {
        if (this.state.edit && !isNaN(value)) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.ref_prev_prescription = value;
            this.setState({ prescription: prescription });
        }
    }

    handleChangeReport = (e, { value }) => {
        this.setState({ report: value });
    }

    handleInputFileImage(e) {
        let files = e.target.files;

        var new_images = [...this.state.new_images];

        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = (f) => {
                new_images.push(f.target.result);
                this.setState({
                    new_images: new_images
                })
            }
        }
    }

    handleInputFile(e) {
        let files = e.target.files;
        var new_files = [...this.state.new_files];

        for (let i = 0; i < files.length; i++) {
            const filename = files[i].name;
            let reader = new FileReader();
            reader.readAsDataURL(files[i]);

            reader.onload = (f) => {
                const element = { name: filename, data: f.target.result }
                new_files.push(element);
                this.setState({
                    new_files: new_files
                })
            }
        }
    }

    handleChangePatienGender = (value) => {
        if (this.state.edit) {
            var prescription = Object.assign({}, this.state.prescription);
            prescription.patient_gender = value;
            this.setState({ prescription: prescription });
        }
    }

    render() {
        const edit_report_buttons = this.state.prescription != null ?
            <div>
                <ReportModal
                    onChangeReport={this.handleChangeReport} onSend={() => this.sendReport()}
                    action_button_content={strings.report_text_button}
                    reports={this.state.prescription != null ? this.state.prescription.reports : []}
                    >    
                </ReportModal>
                <Button className='third-color-background white-color' content={strings.edit_text_button} 
                onClick={() => this.editButton()} />
            </div>:null;

        const new_images = this.state.new_images.map(image => {
            console.log(image);
            return (
                <Image src={image} label={{ as: 'a', color: 'grey', corner: 'left', icon: 'save', size: 'mini' }} />
            )
        });

        const new_files = this.state.new_files.map(file => {
            return (
                <List.Item key={file.url}>
                    <List.Icon size='large' name='save' />
                    <List.Content verticalAlign='middle'>
                        <List.Header as='a' >{file.name}</List.Header>
                    </List.Content>
                </List.Item>
            )
        });

        const images = this.state.prescription != null ? this.state.prescription.images.map(image => {
            return (
                <Image key={image.url} src={image.url} as='a' href={image.url} target='_blank' />
            )
        }) : null;

        const files = this.state.prescription != null ? this.state.prescription.files.map(file => {
            return (
                <List.Item key={file.url}>
                    <List.Icon size='large' name='file' />
                    <List.Content verticalAlign='middle'>
                        <List.Header as='a' href={file.url}>{file.url.substring(file.url.lastIndexOf('/') + 1, file.url.indexOf('?'))}</List.Header>
                    </List.Content>
                </List.Item>
            )
        }) : null;

        const imagesSegment =
            <div>
                <Button fluid onClick={() => this.setState({ image_open: !this.state.image_open })}>
                    <Icon name={this.state.image_open ? 'images' : 'images outline'}></Icon>
                    {this.state.image_open ? strings.images_button_label_close : strings.images_button_label}
                </Button>
                {this.state.image_open ?
                    <Segment >
                        <Button
                            style={{ width: '190px' }}
                            disabled={!this.state.edit}
                            content={strings.upload_new_image_label}
                            labelPosition="left"
                            icon="file"
                            onClick={() => this.imageInputRef.current.click()}
                        />
                        <input
                            ref={this.imageInputRef}
                            type="file"
                            multiple
                            hidden
                            onChange={(e) => this.handleInputFileImage(e)}
                        />

                        <Button
                            style={{ width: '190px' }}
                            disabled={!this.state.edit}
                            content={strings.upload_new_file_label}
                            labelPosition="left"
                            icon="file"
                            onClick={() => this.fileInputRef.current.click()}
                        />
                        <input
                            ref={this.fileInputRef}
                            type="file"
                            hidden
                            onChange={(e) => this.handleInputFile(e)}
                            multiple
                        />
                        <p></p>
                        <Image.Group size='small'>
                            {new_images}
                        </Image.Group>
                        <Image.Group size='small'>
                            {images}
                        </Image.Group>

                        <p></p>

                        <List divided size='medium'>
                            {new_files}
                        </List>

                        <List divided size='medium'>
                            {files}
                        </List>
                    </Segment> : null}

            </div>

        const certificate =
            this.state.prescription != undefined && this.state.prescription.certificate_url != null && this.state.prescription.certificate_url != '' ?
                <div>
                    <br />
                    <Label basic as='a' href={this.state.prescription.certificate_url}>
                        <Icon size='big' name='file' />
                        {strings.download}
                    </Label>
                    <br />
                </div> : <p>{strings.no_certificate}</p>;

        return (
            <div>
                <Grid doubling centered columns={2}>
                    <Grid.Column width={2} className='no-padding-right'>
                        <SideMenu activeItem={config.MENU_ITEM_PRESCRIPTIONS} />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment raised>
                            <OkModal open={this.state.ok} close={() => this.setState({ ok: false })} />
                            <ErrorModal open={this.state.error} close={() => this.setState({ error: false })} />
                            <Grid columns={3}>
                                <Grid.Row >
                                    <Grid.Column textAlign='left' verticalAlign='middle' width={5}>
                                        <Button className='third-color-background white-color' content={strings.back} onClick={() => this.goBack()} />
                                        {this.state.prescription != null ?
                                            <Button.Group color={status_label_color[this.state.prescription.status]}>
                                                <Button>{status_label[this.state.prescription.status]}</Button>
                                                <Dropdown
                                                    className='button icon'
                                                    floating
                                                    trigger={<></>}
                                                    options={statusOptions}
                                                    onChange={this.handleChangePrescriptionStatus}
                                                />
                                            </Button.Group> : null}
                                    </Grid.Column>
                                    <Grid.Column textAlign='center' verticalAlign='middle' width={6}>
                                        <Header as='h3'> {strings.title_prescription} {this.state.prescription != null ? this.state.prescription.id_prescription + " (" + this.state.prescription.datetime.substring(0, 10) + ")" : ''} </Header>
                                        {this.state.prescription != null ? this.state.prescription.dentist.description.toUpperCase() : ''}
                                    </Grid.Column>
                                    <Grid.Column textAlign='right' verticalAlign='middle' width={5}>
                                        {this.state.edit ? <div>
                                            <Button icon="save" color='green' content={strings.save_prescription_button_label} onClick={() => this.saveButton()} />
                                            <Button className='secondary-color-background' content={strings.cancel_prescription_button_label} onClick={() => this.cancelEditButton()} />
                                        </div> :
                                            edit_report_buttons
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Divider />
                            <p></p>
                            <Form error={this.incomplete_submit} >
                                <Grid stackable columns={2}>
                                    <Grid.Row>
                                        <Grid.Column verticalAlign='middle' textAlign='center' width={5}>
                                            <Loader size='big' className='custom-loader' active={this.state.loading} disabled={!this.state.loading} />
                                            <Canvas items={this.state.teeth} />
                                        </Grid.Column>
                                        <Grid.Column width={11}>
                                            <Grid stackable columns={4}>
                                                <Grid.Row>
                                                    <Grid.Column width='6'>
                                                        <Form.Input
                                                            value={this.state.prescription !== null ? this.state.prescription.patient_code : ''}
                                                            label={strings.table_cod_patient} placeholder={strings.table_cod_patient}
                                                            onChange={this.handleChangePatienCode}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width='4' textAlign='center' verticalAlign='bottom'>
                                                        <Form.Field>
                                                            <label> {strings.patient_gender} </label>
                                                            <Button.Group>
                                                                <Button onClick={() => this.handleChangePatienGender('M')} className={this.state.prescription !== null && this.state.prescription.patient_gender == 'M' ? 'third-color-background white-color' : ''}>M</Button>
                                                                <Button onClick={() => this.handleChangePatienGender('F')} className={this.state.prescription !== null && this.state.prescription.patient_gender == 'F' ? 'third-color-background white-color' : ''}>F</Button>
                                                            </Button.Group>
                                                        </Form.Field>

                                                    </Grid.Column>
                                                    <Grid.Column width='3' verticalAlign='bottom'>
                                                        <Form.Input
                                                            value={this.state.prescription !== null ? this.state.prescription.patient_age : ''}
                                                            label={strings.patient_age} placeholder={strings.patient_age}
                                                            onChange={this.handleChangePatientAge}
                                                        />

                                                    </Grid.Column>
                                                    <Grid.Column width='3' verticalAlign='bottom'>
                                                        <Form.Input
                                                            value={this.state.prescription !== null ? this.state.prescription.box_number : ''}
                                                            label={strings.box_number} placeholder={strings.box_number}
                                                            onChange={this.handleChangeBoxNumber}
                                                        />

                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>

                                            <Grid stackable columns={4}>
                                                <Grid.Row>
                                                    <Grid.Column width='5' verticalAlign='bottom'>
                                                        <Form.Field>
                                                            <label> {strings.table_prescription_date_requested} </label>
                                                            <DateInput
                                                                localization='it'
                                                                name="date"
                                                                value={this.state.prescription !== null ? this.state.prescription.date_requested : ""}
                                                                iconPosition="left"
                                                                onChange={this.handleChangeDateRequested}
                                                            />
                                                        </Form.Field>

                                                    </Grid.Column>
                                                    <Grid.Column width='3' verticalAlign='bottom'>
                                                        <Form.Field>
                                                            <label> {strings.table_prescription_time_requested} </label>
                                                            <TimeInput
                                                                localization='it'
                                                                name="time"
                                                                value={this.state.prescription !== null ? this.state.prescription.time_requested : ""}
                                                                iconPosition="left"
                                                                onChange={this.handleChangeTimeRequested}
                                                            />
                                                        </Form.Field>

                                                    </Grid.Column>
                                                    <Grid.Column width='4' verticalAlign='bottom'>
                                                        <Form.Field>
                                                            <label> {strings.start_work} </label>
                                                            <DateInput
                                                                localization='it'
                                                                name="date"
                                                                value={this.state.prescription !== null ? this.state.prescription.date_start_work : ""}
                                                                iconPosition="left"
                                                                onChange={this.handleChangeDateStartWork}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width='4' verticalAlign='bottom'>
                                                        <PrintModal
                                                            colors={colorOptions}
                                                            materials={this.state.materials}
                                                            prescription={this.state.prescription != null ? this.state.prescription : {}}>
                                                        </PrintModal>

                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <p></p>

                                            <Form.TextArea rows={6}
                                                value={this.state.prescription !== null ? this.state.prescription.description : ''}
                                                label={strings.table_prescription_description} placeholder={strings.table_prescription_description}
                                                onChange={this.handleChangeDescription}
                                            />
                                            <Form.Dropdown
                                                label={strings.materials_label}
                                                clearable
                                                fluid
                                                multiple
                                                search
                                                selection
                                                value={this.state.prescription !== null ? this.state.prescription.materials : []}
                                                options={this.state.materials}
                                                placeholder={strings.materials_add_label}
                                                onChange={this.handleChangeMaterials}

                                            />
                                            <Form.Group widths='equal'>

                                                <Form.Dropdown
                                                    label={strings.color_label}
                                                    placeholder={strings.color_label}
                                                    search selection options={colorOptions}
                                                    value={this.state.prescription !== null ? this.state.prescription.tint : ''}
                                                    onChange={this.handleChangeTint}
                                                />
                                                <Form.Input
                                                    value={this.state.prescription !== null ? this.state.prescription.ref_prev_prescription : ''}
                                                    label={strings.ref_prev_prescription}
                                                    placeholder={''}
                                                    action
                                                    onChange={this.handleChangeRefPrevPrescription}>
                                                    <input />
                                                    <Button disabled={this.previous_number > 1 ? true : false} className='third-color-background white-color' onClick={() => this.goPreviousPrescription()} >{strings.go_text_button}</Button>
                                                </Form.Input>
                                            </Form.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <br />
                                <Message info>
                                    <Button onClick={() => this.createCertificate()} className='third-color-background white-color' floated='right'>Genera</Button>
                                    <Message.Header>{strings.certificate}</Message.Header>
                                    {certificate}
                                </Message>

                                {imagesSegment}
                            </Form>
                            {this.state.edit ? <div className='div-right'> <p></p>
                                <Button icon="delete" color='red' content={strings.delete_prescription_button_label} onClick={() => this.open("delete")} /></div> : null}
                            <Confirm style={{ fontSize: "large" }}
                                content={strings.message_lose_data}
                                cancelButton={strings.message_action_giveup}
                                confirmButton={strings.message_action_goon}
                                open={this.state.confirmOpen}
                                onCancel={this.close}
                                onConfirm={this.handleConfirm} />
                        </Segment>
                        <Segment>
                            <Planning
                                date_internal={this.state.prescription != null ? this.state.prescription.date_internal : ''}
                                status={this.state.prescription != null ? this.state.prescription.status : ''}
                                tasks={this.state.prescription != null ? this.state.prescription.tasks : []}
                                prescriptionId={this.state.prescription != null ? this.state.prescription.id_prescription : null}
                            />
                            <p></p>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }


    open = (typeRequest) => this.setState({ confirmOpen: true, typeRequest: typeRequest })
    close = () => this.setState({ confirmOpen: false })
    handleConfirm = () => {
        this.setState({ confirmOpen: false });
        if (this.state.typeRequest === 'delete') {
            this.deletePrescription();
        } else if (this.state.typeRequest === 'cancel') {
            this.cancelEditButton();
        }
        else if (this.state.typeRequest === 'back') {
            this.goBack();
        }
        this.setState({ typeRequest: '' })

    }

}

export default compose(
    withCookies,
    withRouter
)(Prescription)